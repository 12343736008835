import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/features/auth/authSlice";

function AdminChats() {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const role = useSelector(selectRole);

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    // Filter collections when search term changes
    const filtered = collections.filter((collection) =>
      collection.fullname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCollections(filtered);
  }, [collections, searchTerm]);

  const fetchCollections = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RENDER_URL}/user-collections`
      );
      setCollections(response.data);
      // Fetch unread messages for all collections after fetching them
      fetchUnreadMessages(response.data);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  const fetchUnreadMessages = async (collections) => {
    const unreadStatus = [{}];
    for (const user of collections) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_RENDER_URL}/admin/receivers/${user.name}`
        );
        // Assuming the response contains unread messages count or a flag
        let sum =  0;
        for (let i = 0; i < response.data.length; i++) {
          
          if (response.data[i].unreadCount > 0){
            sum = sum + response.data[i].unreadCount;
          }
        }
        unreadStatus[user.name] = sum;
        
      } catch (error) {
        console.error(`Error fetching unread messages for ${user.name}:`, error);
        unreadStatus[user.name] = false; // Default to no unread messages if there's an error
      }
    }
    setUnreadMessages(unreadStatus);
  };

  const handleCollectionClick = async (user) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RENDER_URL}/receivers/${user.name}`
      );

      if (response.data.length > 0) {
        navigate(`/admin/${user.name}`);
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error fetching receiver data:", error);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  if (role !== "admin") {
    return <Navigate to="/" />;
  }

  return (
    <>
      {showAlert && (
        <div className="fixed top-0 right-0 z-50 p-4">
          <div className="bg-red-100 border border-red-400 text-red-700 px-12 py-4 my-auto rounded relative" role="alert">
            <strong className="font-bold">This user has not communicated yet with any volunteer!</strong>
            <button className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer" onClick={handleCloseAlert}>
              <svg className="fill-current h-6 w-6 text-red-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1 1 0 0 1-1.414 1.415l-2.829-2.828-2.828 2.828a1 1 0 1 1-1.414-1.415l2.828-2.828-2.828-2.829a1 1 0 0 1 1.414-1.414l2.828 2.828 2.829-2.828a1 1 0 0 1 1.414 1.414l-2.828 2.829 2.828 2.828z"/></svg>
            </button>
          </div>
        </div>
      )}

      <div className="flex min-h-screen">
        <div className={`w-full bg-gray-200 border-r border-gray-300 p-4`}>
          <h2 className="font-bold text-lg mb-4">Collections</h2>
          <div className="relative">
            <input
              type="text"
              className="border border-gray-300 rounded-md px-3 py-2 w-full mb-4 focus:outline-none focus:ring focus:border-blue-400"
              placeholder="Search collections..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-6rem)]">
            <ul>
              {filteredCollections.map((collection, index) => (
                <li
                  key={index}
                  className="flex py-2 cursor-pointer hover:bg-gray-100 relative"
                  onClick={() => handleCollectionClick(collection)}
                >
                  <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full ">
                    <svg
                      className="absolute w-12 h-12 text-gray-400 -left-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p className="align-self-center ml-2 font-semibold">
                    {collection.name + " (" + collection.fullname + ")"}
                  </p>
                  {unreadMessages[collection.name]>0 &&   (
                    <span className="w-6 h-6 my-auto ml-1 rounded-full bg-green-600 text-center">  {unreadMessages[collection.name]} </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminChats;
